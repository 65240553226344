/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateBillItemDto { 
    status: CreateBillItemDto.StatusEnum;
    txType?: CreateBillItemDto.TxTypeEnum;
    paymentMethod?: CreateBillItemDto.PaymentMethodEnum;
    online_reference?: string;
    invoiceNumber?: number;
    amount: number;
    quantity: number;
    itemId?: string;
    itemName: string;
    itemCode?: string;
    customerId?: string;
    customerName?: string;
    customerUsername?: string;
    customerEmail?: string;
    customerPhone?: string;
    customerSex?: string;
    customerEmailVerified?: boolean;
    departmentRoute?: string;
    hospitalNumber: string;
    appointmentId?: string;
    originalAmount?: number;
    hmoAmount?: number;
    hmoApprovalCode?: string;
    hmoStatus?: CreateBillItemDto.HmoStatusEnum;
    hmoCode?: string;
    customerHmoCode?: string;
    hmoVerifiedById?: string;
    hmoVerifiedByName?: string;
    customerPackage?: string;
    medicalHistoryId?: string;
    /**
     * Important meta information
     */
    meta?: object;
    summary?: string;
    receiverId?: string;
    receiverName?: string;
    receiverUsername?: string;
    raisedById?: string;
    raisedByName?: string;
    raisedByUsername?: string;
    requestId?: string;
    requestCategory?: string;
    slug?: string;
    organizationId?: string;
}
export namespace CreateBillItemDto {
    export type StatusEnum = 'pending' | 'paid' | 'cancelled' | 'paying-online' | 'paid-online' | 'failed-online-payment';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Paid: 'paid' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        PayingOnline: 'paying-online' as StatusEnum,
        PaidOnline: 'paid-online' as StatusEnum,
        FailedOnlinePayment: 'failed-online-payment' as StatusEnum
    };
    export type TxTypeEnum = 'income' | 'expense' | 'discount' | 'reversal';
    export const TxTypeEnum = {
        Income: 'income' as TxTypeEnum,
        Expense: 'expense' as TxTypeEnum,
        Discount: 'discount' as TxTypeEnum,
        Reversal: 'reversal' as TxTypeEnum
    };
    export type PaymentMethodEnum = 'cash' | 'pos' | 'cheque' | 'transfer' | 'online' | 'pay-later' | 'exempted' | 'hmo';
    export const PaymentMethodEnum = {
        Cash: 'cash' as PaymentMethodEnum,
        Pos: 'pos' as PaymentMethodEnum,
        Cheque: 'cheque' as PaymentMethodEnum,
        Transfer: 'transfer' as PaymentMethodEnum,
        Online: 'online' as PaymentMethodEnum,
        PayLater: 'pay-later' as PaymentMethodEnum,
        Exempted: 'exempted' as PaymentMethodEnum,
        Hmo: 'hmo' as PaymentMethodEnum
    };
    export type HmoStatusEnum = 'approved' | 'cancelled' | 'default' | 'in-progress';
    export const HmoStatusEnum = {
        Approved: 'approved' as HmoStatusEnum,
        Cancelled: 'cancelled' as HmoStatusEnum,
        Default: 'default' as HmoStatusEnum,
        InProgress: 'in-progress' as HmoStatusEnum
    };
}


