/**
 * home-clinic-lab-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.8
 * Contact: balocodes@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IMeta { 
    category_id?: number;
    category_name?: string;
    sub_catgory_id?: number;
    sub_category_name?: string;
    patient_name?: string;
}

