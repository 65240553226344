/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IUpdateServiceFields1 } from './iUpdateServiceFields1';
import { HospitalServicesForm } from './hospitalServicesForm';


export interface IUpdateServicePresetsData { 
    form?: Array<HospitalServicesForm>;
    fields?: Array<IUpdateServiceFields1>;
    report?: string;
    preset_key: string;
    preset_title: string;
}

