import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdmissionService } from './api/admission.service';
import { BedsService } from './api/beds.service';
import { DoctorsFileUploadsService } from './api/doctorsFileUploads.service';
import { DoctorsInstructionService } from './api/doctorsInstruction.service';
import { DrugAdministrationService } from './api/drugAdministration.service';
import { FluidIntakeModuleService } from './api/fluidIntakeModule.service';
import { HandOverNotesService } from './api/handOverNotes.service';
import { InvestigationsSamplesService } from './api/investigationsSamples.service';
import { NurseRemarksService } from './api/nurseRemarks.service';
import { ObservationChartsService } from './api/observationCharts.service';
import { RoomTransferRecordService } from './api/roomTransferRecord.service';
import { RoomsService } from './api/rooms.service';
import { RoomsGroupService } from './api/roomsGroup.service';
import { StatisticsService } from './api/statistics.service';
import { WardRoundService } from './api/wardRound.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
