/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AppointmentStatsEnum = 'pending' | 'completed' | 'cancelled' | 'in-progress' | 'vitals-checked' | 'vitals-in-progress' | 'consulting' | 'see-doctor-vitals' | 'see-doctor-lab' | 'see-doctor-red' | 'done' | 'total';

export const AppointmentStatsEnum = {
    Pending: 'pending' as AppointmentStatsEnum,
    Completed: 'completed' as AppointmentStatsEnum,
    Cancelled: 'cancelled' as AppointmentStatsEnum,
    InProgress: 'in-progress' as AppointmentStatsEnum,
    VitalsChecked: 'vitals-checked' as AppointmentStatsEnum,
    VitalsInProgress: 'vitals-in-progress' as AppointmentStatsEnum,
    Consulting: 'consulting' as AppointmentStatsEnum,
    SeeDoctorVitals: 'see-doctor-vitals' as AppointmentStatsEnum,
    SeeDoctorLab: 'see-doctor-lab' as AppointmentStatsEnum,
    SeeDoctorRed: 'see-doctor-red' as AppointmentStatsEnum,
    Done: 'done' as AppointmentStatsEnum,
    Total: 'total' as AppointmentStatsEnum
};

