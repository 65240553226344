/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ServiceType = 'advanced' | 'basic';

export const ServiceType = {
    Advanced: 'advanced' as ServiceType,
    Basic: 'basic' as ServiceType
};

