/**
 * home-clinic-lab-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.8
 * Contact: balocodes@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RadiologyResultData } from './radiologyResultData';
import { IMeta } from './iMeta';
import { LabResultDataFiles } from './labResultDataFiles';


/**
 * Make all properties in T optional
 */
export interface PartialOmitRadiologyResultIdOrCreatedOrUpdatedAtOrDeletedAt { 
    _12?: string;
    patient_name?: string;
    patient_sex?: string;
    patient_dob?: string;
    patient_hmo_code?: string;
    hmo_code?: string;
    appointment_id?: string;
    appointment_code?: string;
    hospital_number?: string;
    hospital_uuid?: string;
    description?: string;
    departmentRoute?: string;
    service_id?: number;
    status?: PartialOmitRadiologyResultIdOrCreatedOrUpdatedAtOrDeletedAt.StatusEnum;
    paymentStatus?: boolean;
    service_name?: string;
    test_package_name?: string;
    test_package_id?: number;
    raised_by_id?: string;
    raised_by_name?: string;
    footnotes?: string;
    summary?: string;
    meta?: IMeta;
    slug?: string;
    updated?: string;
    medical_history_id?: string;
    entered_by_name?: string;
    entered_by_id?: string;
    verified_by_name?: string;
    verified_by_id?: string;
    patient_phone?: string;
    patient_email?: string;
    isEncrypted?: boolean;
    request_id?: number;
    request_id_string?: string;
    fileUrl?: string;
    result?: RadiologyResultData;
    template_result?: string;
    images?: LabResultDataFiles;
    findingKeys?: Array<string>;
}
export namespace PartialOmitRadiologyResultIdOrCreatedOrUpdatedAtOrDeletedAt {
    export type StatusEnum = 'verified' | 'un-verified';
    export const StatusEnum = {
        Verified: 'verified' as StatusEnum,
        UnVerified: 'un-verified' as StatusEnum
    };
}


