/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface WalletEntity { 
    id: number;
    created: string;
    updated: string;
    slug?: string;
    hospital_number?: string;
    balance?: number;
    status?: WalletEntity.StatusEnum;
    deletedAt?: string;
}
export namespace WalletEntity {
    export type StatusEnum = 'open' | 'blocked';
    export const StatusEnum = {
        Open: 'open' as StatusEnum,
        Blocked: 'blocked' as StatusEnum
    };
}


