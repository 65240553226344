/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickLeanDocumentTExcludeKeyofLeanDocumentTExcludeKeyofDocumentIdOrIdOrVOr24isSingleNested { 
    _id?: object;
    /**
     * This documents __v.
     */
    __v?: any | null;
    /**
     * The string version of this documents _id.
     */
    id?: any | null;
}

