/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HospitalServicesFindings } from './hospitalServicesFindings';
import { IUpdateServicePresetsData } from './iUpdateServicePresetsData';
import { ServiceType } from './serviceType';
import { Sex } from './sex';
import { HospitalServicesSpecialCost } from './hospitalServicesSpecialCost';
import { ServiceAccessType } from './serviceAccessType';
import { IUpdateServiceFields } from './iUpdateServiceFields';
import { GroupedFieldsType } from './groupedFieldsType';
import { HospitalServicesAgeRange } from './hospitalServicesAgeRange';


export interface IUpdateService { 
    service_cost?: number;
    department_route?: string;
    findings?: Array<HospitalServicesFindings>;
    accessType?: ServiceAccessType;
    special_cost?: HospitalServicesSpecialCost;
    age_range?: HospitalServicesAgeRange;
    sex?: Sex;
    follow_up_cost?: number;
    service_validity_in_days?: number;
    service_type?: ServiceType;
    service_name?: string;
    sub_category?: number | null;
    sub_category_key?: string;
    category_key?: string;
    grouped_fields?: GroupedFieldsType;
    category?: number | null;
    percentage_discount?: number;
    service_key: string;
    fields?: Array<IUpdateServiceFields>;
    preset?: boolean;
    presets_data?: Array<IUpdateServicePresetsData>;
}

