/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientDetailsForHandOverNotes } from './patientDetailsForHandOverNotes';


export interface FetchHandOverNoteDto { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    staffId?: string;
    staffFullName?: string;
    totalPatientsOnHandingOver?: number;
    noOfPatientsOnAdmission?: number;
    noOfPatientsDischarge?: number;
    noOfPatientsTransferedIn?: number;
    noOfPatientsTransferedOut?: number;
    noOfBabies?: number;
    totalInPatients?: number;
    noDischargedAgainstMedicalAdvice?: number;
    noOfRefferal?: number;
    snapShotStartDateTime?: string;
    snapShotEndDateTime?: string;
    snapShotNote?: string;
    patientsData?: Array<PatientDetailsForHandOverNotes>;
}

