import { createAction, props } from '@ngrx/store';
import { RadiologyRequest as ScanRequest } from 'src/core/api/v1/lab-api';

export const storeScanRequests = createAction(
  'StoreScanRequests [ScanRequestsComponent]',
  props<{ requests: ScanRequest[] }>()
);

export const addScanRequest = createAction(
  'AddScanRequst',
  props<{ request: ScanRequest }>()
);

export const addScanRequests = createAction(
  'AddScanRequst',
  props<{ requests: ScanRequest[] }>()
);

export const updateScanRequest = createAction(
  'UpdateScanRequest',
  props<{ request: Partial<ScanRequest> }>()
);

export const updateScanRequests = createAction(
  'UpdateScanRequest',
  props<{ requests: Partial<ScanRequest>[] }>()
);

export const removeScanRequest = createAction(
  'RemoveScanRequest',
  props<{ request: ScanRequest }>()
);
