/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IPreoperativeCheckListXray } from './iPreoperativeCheckListXray';
import { ChecklistAnswers } from './checklistAnswers';


/**
 * Make all properties in T optional
 */
export interface PartialIPreoperativeCheckList { 
    _id?: string | null;
    hospitalNumber?: string;
    appointmentId?: string;
    patientId?: string;
    patientHmoCode?: string;
    enteredByUserName?: string;
    enteredById?: string | null;
    enteredByFullName?: string;
    patientRecievedInOtByName?: string;
    patientRecievedInOtById?: string;
    recievedByName?: string;
    recievedByTime?: string;
    status?: PartialIPreoperativeCheckList.StatusEnum;
    bp?: string;
    temp?: string;
    pulse?: string;
    respiratory_rate?: string;
    blood_sugar?: string;
    time?: string;
    bath?: ChecklistAnswers;
    voided_before_shifting_to_Ot?: ChecklistAnswers;
    on_continous_bladder_drainage?: ChecklistAnswers;
    bowel_preparation?: ChecklistAnswers;
    pre_op_medication_administered?: ChecklistAnswers;
    surgery_site_marked?: ChecklistAnswers;
    skin_preparation?: ChecklistAnswers;
    nbm?: ChecklistAnswers;
    dentures_removed?: ChecklistAnswers;
    contact_lenses_glasses_removed?: ChecklistAnswers;
    prothesis_present?: ChecklistAnswers;
    nail_polished_removed?: ChecklistAnswers;
    jewelry_innergarments_removed_handed_over?: ChecklistAnswers;
    blood_group_and_rh_typing?: string;
    cross_match?: string;
    consent_taken?: ChecklistAnswers;
    HIV?: ChecklistAnswers;
    Hbs_Ag?: ChecklistAnswers;
    MRSA?: ChecklistAnswers;
    HCV?: ChecklistAnswers;
    antibiotic_given_at?: string;
    scrub_done_if_indicated?: ChecklistAnswers;
    billing_clearance_given_by?: string;
    instruction_to_recovery_staff?: string;
    xray?: IPreoperativeCheckListXray;
    ecg_echo?: IPreoperativeCheckListXray;
    ultra_sound?: IPreoperativeCheckListXray;
    ct_scan?: IPreoperativeCheckListXray;
    mri_scan?: IPreoperativeCheckListXray;
    admission_slip?: IPreoperativeCheckListXray;
    blue_folder?: IPreoperativeCheckListXray;
    billing_card?: IPreoperativeCheckListXray;
    mounting_chart?: IPreoperativeCheckListXray;
    tmt?: IPreoperativeCheckListXray;
    others?: IPreoperativeCheckListXray;
}
export namespace PartialIPreoperativeCheckList {
    export type StatusEnum = 'processing' | 'recieved';
    export const StatusEnum = {
        Processing: 'processing' as StatusEnum,
        Recieved: 'recieved' as StatusEnum
    };
}


