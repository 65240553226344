/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoomEntity } from './roomEntity';


export interface FetchRoomGroupDto { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    groupName?: string;
    description?: string;
    cost?: string;
    rooms?: Array<RoomEntity>;
}

