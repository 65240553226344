/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ICardCollection { 
    _id?: string;
    hospital_number: string;
    patient_name: string;
    uid: string;
    printed_by_name: string;
    printed_by_id: string;
    slug?: string;
    createdAt?: string;
    updatedAt?: string;
}

