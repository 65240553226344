/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Category } from './category';
import { HospitalServicesFields } from './hospitalServicesFields';
import { HospitalServicesFindings } from './hospitalServicesFindings';
import { HospitalServicesPresetsData } from './hospitalServicesPresetsData';
import { ServiceType } from './serviceType';
import { Sex } from './sex';
import { HospitalServicesSpecialCost } from './hospitalServicesSpecialCost';
import { ServiceAccessType } from './serviceAccessType';
import { SubCategory } from './subCategory';
import { PackageEntity } from './packageEntity';
import { GroupedFieldsType } from './groupedFieldsType';
import { HospitalServicesAgeRange } from './hospitalServicesAgeRange';


export interface HospitalServices { 
    id?: number;
    service_name: string;
    category?: number | Category | null;
    category_key?: string;
    department_route?: string;
    grouped_fields?: GroupedFieldsType;
    sub_category: number | SubCategory | null;
    sub_category_key?: string;
    service_type?: ServiceType;
    service_key?: string;
    packages?: Array<PackageEntity>;
    slug?: string;
    service_cost?: number;
    sex?: Sex;
    follow_up_cost?: number;
    service_validity_in_days?: number;
    special_cost?: HospitalServicesSpecialCost;
    age_range?: HospitalServicesAgeRange;
    findings?: Array<HospitalServicesFindings>;
    percentage_discount?: number;
    fields?: Array<HospitalServicesFields>;
    preset?: boolean;
    presets_data?: Array<HospitalServicesPresetsData>;
    accessType?: ServiceAccessType;
    createdAt?: string;
    updatedAt?: string;
}

