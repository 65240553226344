/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FieldType = 'input' | 'text_area' | 'boolean' | 'number' | 'radio' | 'range' | 'mixed' | 'table' | 'object' | 'datetime-local' | 'measurement' | 'time' | 'table-sensitivity' | 'checkbox' | 'table-widal';

export const FieldType = {
    Input: 'input' as FieldType,
    TextArea: 'text_area' as FieldType,
    Boolean: 'boolean' as FieldType,
    Number: 'number' as FieldType,
    Radio: 'radio' as FieldType,
    Range: 'range' as FieldType,
    Mixed: 'mixed' as FieldType,
    Table: 'table' as FieldType,
    Object: 'object' as FieldType,
    DatetimeLocal: 'datetime-local' as FieldType,
    Measurement: 'measurement' as FieldType,
    Time: 'time' as FieldType,
    TableSensitivity: 'table-sensitivity' as FieldType,
    Checkbox: 'checkbox' as FieldType,
    TableWidal: 'table-widal' as FieldType
};

