/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ChecklistAnswers = 'yes' | 'no' | 'n/a';

export const ChecklistAnswers = {
    Yes: 'yes' as ChecklistAnswers,
    No: 'no' as ChecklistAnswers,
    NA: 'n/a' as ChecklistAnswers
};

