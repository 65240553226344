/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Sex = 'male' | 'female' | 'both';

export const Sex = {
    Male: 'male' as Sex,
    Female: 'female' as Sex,
    Both: 'both' as Sex
};

