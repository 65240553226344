/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Department } from './department';
import { Category } from './category';
import { HospitalServices } from './hospitalServices';


export interface SubCategory { 
    id?: number;
    sub_category_name: string;
    sub_category_key?: string;
    services?: Array<HospitalServices>;
    category: number | Category | null;
    department: number | Department | null;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

