/**
 * home-clinic-lab-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.8
 * Contact: balocodes@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RadiologyResultDataValues } from './radiologyResultDataValues';
import { RadiologyResultDataFindings } from './radiologyResultDataFindings';


export interface RadiologyResultData { 
    findings?: Array<RadiologyResultDataFindings>;
    footnotes?: string;
    notes?: string;
    description: string;
    status?: string;
    values: Array<RadiologyResultDataValues>;
}

