/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FetchFluidIntakeDto { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    staffId?: string;
    staffName?: string;
    patientId?: string;
    drainsType?: FetchFluidIntakeDto.DrainsTypeEnum;
    drainsValue?: string;
    drainsUnit?: string;
    IVFluidValue?: string;
    IVFluidType?: FetchFluidIntakeDto.IVFluidTypeEnum;
    IVFluidUnit?: string;
    fluidIntakeInputType?: FetchFluidIntakeDto.FluidIntakeInputTypeEnum;
    hospitalNumber?: string;
    slug?: string;
    patientName?: string;
    admissionNumber?: number;
    appointmentId?: string;
    oralValue?: string;
    oralUnit?: string;
    urineValue?: string;
    urineUnit?: string;
    emesis?: string;
    gastricSuction?: string;
    others?: string;
    doctorsReview?: string;
    period?: FetchFluidIntakeDto.PeriodEnum;
    remark?: FetchFluidIntakeDto.RemarkEnum;
    pharmacyRequestId?: string;
    prescriptionId?: string;
    prescriptionFormat?: string;
    prescriptionDrugName?: string;
    startTime?: string;
    stopTime?: string;
}
export namespace FetchFluidIntakeDto {
    export type DrainsTypeEnum = 'Chest' | 'Abdominal' | 'bladder' | 'Others';
    export const DrainsTypeEnum = {
        Chest: 'Chest' as DrainsTypeEnum,
        Abdominal: 'Abdominal' as DrainsTypeEnum,
        Bladder: 'bladder' as DrainsTypeEnum,
        Others: 'Others' as DrainsTypeEnum
    };
    export type IVFluidTypeEnum = '5% Dextrose water' | '10% Dextrose water' | '50% Dextrose water' | '5% Dextrose saline' | '4.3% Dextrose saline' | '10% Dextrose saline' | '0.9% Normal saline' | '0.45% Normal saline' | 'Ringers lactate' | 'Full strength Darrow\'s' | 'Half strength Darrow\'s' | 'Bicarbonate' | '10% Mannitol' | '20% Mannitol' | 'Blood' | 'Ciprofloxacin' | 'Tinidazole' | 'Metronidazole';
    export const IVFluidTypeEnum = {
        _5DextroseWater: '5% Dextrose water' as IVFluidTypeEnum,
        _10DextroseWater: '10% Dextrose water' as IVFluidTypeEnum,
        _50DextroseWater: '50% Dextrose water' as IVFluidTypeEnum,
        _5DextroseSaline: '5% Dextrose saline' as IVFluidTypeEnum,
        _43DextroseSaline: '4.3% Dextrose saline' as IVFluidTypeEnum,
        _10DextroseSaline: '10% Dextrose saline' as IVFluidTypeEnum,
        _09NormalSaline: '0.9% Normal saline' as IVFluidTypeEnum,
        _045NormalSaline: '0.45% Normal saline' as IVFluidTypeEnum,
        RingersLactate: 'Ringers lactate' as IVFluidTypeEnum,
        FullStrengthDarrows: 'Full strength Darrow\'s' as IVFluidTypeEnum,
        HalfStrengthDarrows: 'Half strength Darrow\'s' as IVFluidTypeEnum,
        Bicarbonate: 'Bicarbonate' as IVFluidTypeEnum,
        _10Mannitol: '10% Mannitol' as IVFluidTypeEnum,
        _20Mannitol: '20% Mannitol' as IVFluidTypeEnum,
        Blood: 'Blood' as IVFluidTypeEnum,
        Ciprofloxacin: 'Ciprofloxacin' as IVFluidTypeEnum,
        Tinidazole: 'Tinidazole' as IVFluidTypeEnum,
        Metronidazole: 'Metronidazole' as IVFluidTypeEnum
    };
    export type FluidIntakeInputTypeEnum = 'input' | 'output' | 'both';
    export const FluidIntakeInputTypeEnum = {
        Input: 'input' as FluidIntakeInputTypeEnum,
        Output: 'output' as FluidIntakeInputTypeEnum,
        Both: 'both' as FluidIntakeInputTypeEnum
    };
    export type PeriodEnum = 'MORNING' | 'AFTERNOON' | 'EVENING';
    export const PeriodEnum = {
        Morning: 'MORNING' as PeriodEnum,
        Afternoon: 'AFTERNOON' as PeriodEnum,
        Evening: 'EVENING' as PeriodEnum
    };
    export type RemarkEnum = 'MORNING' | 'AFTERNOON' | 'EVENING';
    export const RemarkEnum = {
        Morning: 'MORNING' as RemarkEnum,
        Afternoon: 'AFTERNOON' as RemarkEnum,
        Evening: 'EVENING' as RemarkEnum
    };
}


