/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NurseRemarkEntity } from './nurseRemarkEntity';


export interface DrugAdministrationsEntity { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    prescriptionId?: string;
    prescribedById?: string;
    prescribedByFullName?: string;
    drugName?: string;
    drugCostPerUnit?: number;
    drugQuantity?: number;
    prescriptionFormat?: string;
    patientFullName?: string;
    appointmentId?: string;
    progressIndex?: number;
    administeredById?: string;
    administeredStatus?: DrugAdministrationsEntity.AdministeredStatusEnum;
    administeredByName?: string;
    route?: string;
    patientId?: string;
    hospitalNumber?: string;
    admissionNumber?: number;
    nurseRemarks?: Array<NurseRemarkEntity>;
    admissionId?: number;
}
export namespace DrugAdministrationsEntity {
    export type AdministeredStatusEnum = 'UNCONFIRMED' | 'CONFIRMED' | 'PENDING' | 'MISSED' | 'READY';
    export const AdministeredStatusEnum = {
        Unconfirmed: 'UNCONFIRMED' as AdministeredStatusEnum,
        Confirmed: 'CONFIRMED' as AdministeredStatusEnum,
        Pending: 'PENDING' as AdministeredStatusEnum,
        Missed: 'MISSED' as AdministeredStatusEnum,
        Ready: 'READY' as AdministeredStatusEnum
    };
}


