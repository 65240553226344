/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FetchDrugAdministeredRecordDto } from './fetchDrugAdministeredRecordDto';


export interface FetchDrugAdministeredRecordResponseDtoData { 
    list?: Array<FetchDrugAdministeredRecordDto>;
    count?: number;
    page?: number;
}

