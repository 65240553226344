export * from './admission.service';
import { AdmissionService } from './admission.service';
export * from './beds.service';
import { BedsService } from './beds.service';
export * from './doctorsFileUploads.service';
import { DoctorsFileUploadsService } from './doctorsFileUploads.service';
export * from './doctorsInstruction.service';
import { DoctorsInstructionService } from './doctorsInstruction.service';
export * from './drugAdministration.service';
import { DrugAdministrationService } from './drugAdministration.service';
export * from './fluidIntakeModule.service';
import { FluidIntakeModuleService } from './fluidIntakeModule.service';
export * from './handOverNotes.service';
import { HandOverNotesService } from './handOverNotes.service';
export * from './investigationsSamples.service';
import { InvestigationsSamplesService } from './investigationsSamples.service';
export * from './nurseRemarks.service';
import { NurseRemarksService } from './nurseRemarks.service';
export * from './observationCharts.service';
import { ObservationChartsService } from './observationCharts.service';
export * from './roomTransferRecord.service';
import { RoomTransferRecordService } from './roomTransferRecord.service';
export * from './rooms.service';
import { RoomsService } from './rooms.service';
export * from './roomsGroup.service';
import { RoomsGroupService } from './roomsGroup.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './wardRound.service';
import { WardRoundService } from './wardRound.service';
export const APIS = [AdmissionService, BedsService, DoctorsFileUploadsService, DoctorsInstructionService, DrugAdministrationService, FluidIntakeModuleService, HandOverNotesService, InvestigationsSamplesService, NurseRemarksService, ObservationChartsService, RoomTransferRecordService, RoomsService, RoomsGroupService, StatisticsService, WardRoundService];
