/**
 * home-clinic-lab-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.8
 * Contact: balocodes@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GenerateBillInfo { 
    requestId: number;
    requestType: GenerateBillInfo.RequestTypeEnum;
}
export namespace GenerateBillInfo {
    export type RequestTypeEnum = 'LAB' | 'RADIOLOGY';
    export const RequestTypeEnum = {
        Lab: 'LAB' as RequestTypeEnum,
        Radiology: 'RADIOLOGY' as RequestTypeEnum
    };
}


