/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ITransfusionOrderPretransfusionVitals } from './iTransfusionOrderPretransfusionVitals';
import { ITransfusionOrderBloodTransfusion } from './iTransfusionOrderBloodTransfusion';


export interface ITransfusionOrder { 
    _id?: string | null;
    hospitalNumber: string;
    firstName: string;
    lastName: string;
    otherName?: string;
    sex: ITransfusionOrder.SexEnum;
    phone?: string;
    email?: string;
    cost?: number;
    patientId?: string;
    patientHmoCode?: string;
    hmoCode?: string;
    hospitalBranch?: string;
    appointmentId?: string;
    admissionId?: string;
    paymentStatus?: boolean;
    status?: ITransfusionOrder.StatusEnum;
    raisedByUserName?: string;
    raisedById?: string | null;
    raisedByFullName?: string;
    completedByUserName?: string;
    completedByById?: string | null;
    completedByFullName?: string;
    volumeOfBlood?: string;
    bloodType?: string;
    durationOfBloodTransfusion?: string;
    lasixCover?: string;
    lasixDosage?: string;
    compatibilityOfPatientandDonorsBlood?: string;
    bloodBagNumber?: string;
    collectionDate?: string;
    expiryDate?: string;
    pretransfusionVitals?: ITransfusionOrderPretransfusionVitals;
    posttransfusionVitals?: ITransfusionOrderPretransfusionVitals;
    bloodTransfusion?: ITransfusionOrderBloodTransfusion;
}
export namespace ITransfusionOrder {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type StatusEnum = 'pending' | 'in-progress' | 'completed';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        InProgress: 'in-progress' as StatusEnum,
        Completed: 'completed' as StatusEnum
    };
}


