/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DoctorInstruction } from './doctorInstruction';


export interface DoctorInstructionResponse { 
    single?: DoctorInstruction;
    multiple?: Array<DoctorInstruction>;
    error?: boolean;
    message?: string;
}

