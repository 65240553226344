/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillFilterParams } from './billFilterParams';


export interface BillFilterDto { 
    startDate?: string;
    endDate?: string;
    limit?: number;
    page?: number;
    filter?: BillFilterParams;
    order?: object;
    onlyHmo?: string;
    distinctOn?: Array<string>;
    searchString?: string;
}

