import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LabRequest, RadiologyRequest } from 'src/core/api/v1/lab-api';
import { environment } from 'src/environments/environment';
import io from 'socket.io-client';
import {
  addLabRequest,
  addLabRequests,
  storeLabRequests,
  updateLabRequest,
  updateLabRequests,
} from '../store/lab-request.actions';
import { addScanRequest, addScanRequests, updateScanRequest, updateScanRequests } from '../store/scan-request.actions';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  newRequestSound = new Audio('assets/sound/cleared-request.wav');
  updatedRequestSound = new Audio('assets/sound/new-request.wav');

  constructor(private store: Store) {
    const manager = io(environment.socket_url);
    manager.connect();

    manager.on('created-lab-requests', (val: LabRequest[] | LabRequest) => {
      if (Array.isArray(val)) {
        this.store.dispatch(addLabRequests({ requests: val }));
      } else {
        this.store.dispatch(addLabRequest({ request: val }));
      }
      this.playSound('add');
    });

    manager.on(
      'updated-lab-requests',
      (val: Partial<LabRequest>[] | Partial<LabRequest>) => {
        if (Array.isArray(val)) {
          this.store.dispatch(updateLabRequests({ requests: val }));
        } else {
          this.store.dispatch(updateLabRequest({ request: val }));
        }
        this.playSound('clear');
      }
    );

    manager.on('created-scan-requests', (val: RadiologyRequest[] | RadiologyRequest) => {
      if (Array.isArray(val)) {
        this.store.dispatch(addScanRequests({ requests: val }));
      } else {
        this.store.dispatch(addLabRequest({ request: val }));
      }
      this.playSound('add');
    });

    manager.on(
      'updated-lab-requests',
      (val: Partial<RadiologyRequest>[] | Partial<RadiologyRequest>) => {
        if (Array.isArray(val)) {
          this.store.dispatch(updateScanRequests({ requests: val }));
        } else {
          this.store.dispatch(updateScanRequest({ request: val }));
        }
        this.playSound('clear');
      }
    );

    manager.emit('join_room', { roomId: 'LAB' })
    manager.emit('join_room', { roomId: 'SCAN' })
  }

  playSound(val: 'add' | 'clear') {
    if (val === 'add') {
      this.newRequestSound.load();
      this.newRequestSound.play();
    }
    if (val === 'clear') {
      this.updatedRequestSound.load();
      this.updatedRequestSound.play();
    }
  }
}
