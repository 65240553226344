<router-outlet> </router-outlet>

<ng-template #leftSystem>
  <mat-dialog-content>
    <div class="text-center">
      <h2>Where have you been?</h2>
      <h4>You have been away since {{ awayTime | date : "short" }}?</h4>
    </div>
    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="matDialogRef?.close()">
        CLOSE
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</ng-template>