/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IPatientAdditionalHistories { 
    isSpecial?: boolean;
    dateAddedToPatientRecord?: string;
    text?: string;
    key?: string;
    _id?: string;
}

