/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ICheckIn { 
    _id?: string;
    checkInTime?: string;
    hospitalNumber: string;
    hospitalId?: string;
    hospitalBranch?: string;
    firstName?: string;
    lastName?: string;
    sex?: ICheckIn.SexEnum;
    created_at?: string;
    updated_at?: string;
}
export namespace ICheckIn {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
}


