/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ICreateAppointmentParamsQa } from './iCreateAppointmentParamsQa';
import { ICreateAppointmentParamsLocationCoordinates } from './iCreateAppointmentParamsLocationCoordinates';
import { ICreateAppointmentParamsPastConsultants } from './iCreateAppointmentParamsPastConsultants';


/**
 * Make all properties in T optional
 */
export interface PartialIAppointment { 
    _id?: string;
    clinic?: string;
    departmentName?: string;
    departmentRoute?: string;
    departmentId?: string;
    practitioner?: string;
    practitionerFirstName?: string;
    practitionerLastName?: string;
    practitionerId?: string;
    date_of_appointment?: string;
    type_of_consultation?: PartialIAppointment.TypeOfConsultationEnum;
    status?: PartialIAppointment.StatusEnum;
    paymentStatus?: boolean;
    patient?: string | null;
    admission_id?: string;
    patientHmoCode?: string;
    hmoCode?: string;
    email?: string;
    phone?: string;
    packageName?: string;
    hospital_number?: string;
    uid?: string;
    sex?: PartialIAppointment.SexEnum;
    comment?: string;
    patientFirstName?: string;
    patientLastName?: string;
    createdByFirstName?: string;
    createdByLastName?: string;
    createdById?: string;
    created_at?: string;
    updated_at?: string;
    pastConsultants?: Array<ICreateAppointmentParamsPastConsultants>;
    doctorsInstruction?: string;
    lastAppointmentId?: string | null;
    slug?: string;
    hospital_branch?: string;
    age?: number;
    subscriptionId?: string | null;
    /**
     * serviceId is the id of the service that the appointment is for. It is required though set to optional here for backward compatibility
     */
    serviceId?: string;
    /**
     * serviceKey is the key of the service that the appointment is for. It is required though set to optional here for backward compatibility
     */
    serviceKey?: string;
    /**
     * Please supply this cost always though it is set to optional here for backward compatibility
     */
    cost?: number;
    completed_at?: string;
    qa?: Array<ICreateAppointmentParamsQa>;
    completedComplaints?: Array<string>;
    bookingType?: PartialIAppointment.BookingTypeEnum;
    isEmergency?: boolean;
    needsAmbulance?: boolean;
    locationCoordinates?: ICreateAppointmentParamsLocationCoordinates;
    address?: string;
    checkInStatus?: PartialIAppointment.CheckInStatusEnum;
    checkInTime?: string;
    checkOutTime?: string;
}
export namespace PartialIAppointment {
    export type TypeOfConsultationEnum = 'first-time' | 'follow-up' | 'referral' | 'ward-round';
    export const TypeOfConsultationEnum = {
        FirstTime: 'first-time' as TypeOfConsultationEnum,
        FollowUp: 'follow-up' as TypeOfConsultationEnum,
        Referral: 'referral' as TypeOfConsultationEnum,
        WardRound: 'ward-round' as TypeOfConsultationEnum
    };
    export type StatusEnum = 'pending' | 'vitals-checked' | 'in-progress' | 'done' | 'cancelled' | 'vitals-in-progress' | 'consulting' | 'see-doctor-vitals' | 'see-doctor-lab' | 'see-doctor-rad';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        VitalsChecked: 'vitals-checked' as StatusEnum,
        InProgress: 'in-progress' as StatusEnum,
        Done: 'done' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        VitalsInProgress: 'vitals-in-progress' as StatusEnum,
        Consulting: 'consulting' as StatusEnum,
        SeeDoctorVitals: 'see-doctor-vitals' as StatusEnum,
        SeeDoctorLab: 'see-doctor-lab' as StatusEnum,
        SeeDoctorRad: 'see-doctor-rad' as StatusEnum
    };
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type BookingTypeEnum = 'self' | 'other';
    export const BookingTypeEnum = {
        Self: 'self' as BookingTypeEnum,
        Other: 'other' as BookingTypeEnum
    };
    export type CheckInStatusEnum = 'pending' | 'checkin' | 'checkout';
    export const CheckInStatusEnum = {
        Pending: 'pending' as CheckInStatusEnum,
        Checkin: 'checkin' as CheckInStatusEnum,
        Checkout: 'checkout' as CheckInStatusEnum
    };
}


