/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoomEntity } from './roomEntity';
import { BedEntity } from './bedEntity';


export interface RoomTransferRecord { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    fromRoom: RoomEntity;
    fromBed: BedEntity;
    toRoom: RoomEntity;
    toBed: BedEntity;
    admissionRecord: number;
    hospitalNumber: string;
    transferedAt: string;
    roomCost: number;
    transferedById: string;
    transferedByName: string;
}

