/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IHistoryOfPresentPregnancy { 
    _id?: string | null;
    appointmentId: string | null;
    enrollmentId: string | null;
    followUpVisitId?: string | null;
    bleeding?: boolean;
    urinarySymptoms?: boolean;
    discharge?: boolean;
    swellingAnkles?: boolean;
    otherSymptoms?: string;
    comment?: string;
    created_at?: string;
    updated_at?: string;
}

