/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HospitalServicesSpecialCostHmoCosts } from './hospitalServicesSpecialCostHmoCosts';
import { HospitalServicesSpecialCostPackagesCost } from './hospitalServicesSpecialCostPackagesCost';


export interface HospitalServicesSpecialCost { 
    hmo_costs?: Array<HospitalServicesSpecialCostHmoCosts>;
    packages_cost?: Array<HospitalServicesSpecialCostPackagesCost>;
    default_cost?: number;
}

