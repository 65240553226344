/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { CreateHandOverNoteDto } from '../model/createHandOverNoteDto';
// @ts-ignore
import { CreateHandOverNoteResponseDto } from '../model/createHandOverNoteResponseDto';
// @ts-ignore
import { FetchHandOverNoteResponseDto } from '../model/fetchHandOverNoteResponseDto';
// @ts-ignore
import { HandOverNotesFilterOptions } from '../model/handOverNotesFilterOptions';
// @ts-ignore
import { PatientSummaryFilterOptions } from '../model/patientSummaryFilterOptions';
// @ts-ignore
import { UpdateHandOverNoteDto } from '../model/updateHandOverNoteDto';
// @ts-ignore
import { UpdateHandOverNoteResponseDto } from '../model/updateHandOverNoteResponseDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class HandOverNotesService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param createHandOverNoteDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handOverNotesControllerCreate(createHandOverNoteDto: CreateHandOverNoteDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreateHandOverNoteResponseDto>;
    public handOverNotesControllerCreate(createHandOverNoteDto: CreateHandOverNoteDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreateHandOverNoteResponseDto>>;
    public handOverNotesControllerCreate(createHandOverNoteDto: CreateHandOverNoteDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreateHandOverNoteResponseDto>>;
    public handOverNotesControllerCreate(createHandOverNoteDto: CreateHandOverNoteDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (createHandOverNoteDto === null || createHandOverNoteDto === undefined) {
            throw new Error('Required parameter createHandOverNoteDto was null or undefined when calling handOverNotesControllerCreate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<CreateHandOverNoteResponseDto>(`${this.configuration.basePath}/hand-over-notes/create`,
            createHandOverNoteDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param limit 
     * @param page 
     * @param startDate 
     * @param endDate 
     * @param filterProperty property to use when fetching dates
     * @param note 
     * @param patientId 
     * @param hospitalNumber 
     * @param patientState 
     * @param admissionNumber 
     * @param timeTaken 
     * @param roomId 
     * @param roomName 
     * @param roomGroupId 
     * @param roomGroupName 
     * @param transferType 
     * @param transferNote 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handOverNotesControllerFindAll(limit?: number, page?: number, startDate?: string, endDate?: string, filterProperty?: 'snapShotStartDateTime' | 'createdAt', note?: string, patientId?: string, hospitalNumber?: string, patientState?: 'stable' | 'critical' | 'deceased' | 'icu' | 'had_procedure', admissionNumber?: string, timeTaken?: string, roomId?: string, roomName?: string, roomGroupId?: string, roomGroupName?: string, transferType?: 'IN' | 'OUT', transferNote?: string, id?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<FetchHandOverNoteResponseDto>;
    public handOverNotesControllerFindAll(limit?: number, page?: number, startDate?: string, endDate?: string, filterProperty?: 'snapShotStartDateTime' | 'createdAt', note?: string, patientId?: string, hospitalNumber?: string, patientState?: 'stable' | 'critical' | 'deceased' | 'icu' | 'had_procedure', admissionNumber?: string, timeTaken?: string, roomId?: string, roomName?: string, roomGroupId?: string, roomGroupName?: string, transferType?: 'IN' | 'OUT', transferNote?: string, id?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<FetchHandOverNoteResponseDto>>;
    public handOverNotesControllerFindAll(limit?: number, page?: number, startDate?: string, endDate?: string, filterProperty?: 'snapShotStartDateTime' | 'createdAt', note?: string, patientId?: string, hospitalNumber?: string, patientState?: 'stable' | 'critical' | 'deceased' | 'icu' | 'had_procedure', admissionNumber?: string, timeTaken?: string, roomId?: string, roomName?: string, roomGroupId?: string, roomGroupName?: string, transferType?: 'IN' | 'OUT', transferNote?: string, id?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<FetchHandOverNoteResponseDto>>;
    public handOverNotesControllerFindAll(limit?: number, page?: number, startDate?: string, endDate?: string, filterProperty?: 'snapShotStartDateTime' | 'createdAt', note?: string, patientId?: string, hospitalNumber?: string, patientState?: 'stable' | 'critical' | 'deceased' | 'icu' | 'had_procedure', admissionNumber?: string, timeTaken?: string, roomId?: string, roomName?: string, roomGroupId?: string, roomGroupName?: string, transferType?: 'IN' | 'OUT', transferNote?: string, id?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (limit !== undefined && limit !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>limit, 'limit');
        }
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (startDate !== undefined && startDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>startDate, 'start_date');
        }
        if (endDate !== undefined && endDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>endDate, 'end_date');
        }
        if (filterProperty !== undefined && filterProperty !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filterProperty, 'filter_property');
        }
        if (note !== undefined && note !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>note, 'note');
        }
        if (patientId !== undefined && patientId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>patientId, 'patientId');
        }
        if (hospitalNumber !== undefined && hospitalNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>hospitalNumber, 'hospitalNumber');
        }
        if (patientState !== undefined && patientState !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>patientState, 'patientState');
        }
        if (admissionNumber !== undefined && admissionNumber !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>admissionNumber, 'admissionNumber');
        }
        if (timeTaken !== undefined && timeTaken !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>timeTaken, 'timeTaken');
        }
        if (roomId !== undefined && roomId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>roomId, 'roomId');
        }
        if (roomName !== undefined && roomName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>roomName, 'roomName');
        }
        if (roomGroupId !== undefined && roomGroupId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>roomGroupId, 'roomGroupId');
        }
        if (roomGroupName !== undefined && roomGroupName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>roomGroupName, 'roomGroupName');
        }
        if (transferType !== undefined && transferType !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transferType, 'transferType');
        }
        if (transferNote !== undefined && transferNote !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>transferNote, 'transferNote');
        }
        if (id !== undefined && id !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>id, 'id');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<FetchHandOverNoteResponseDto>(`${this.configuration.basePath}/hand-over-notes/list`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param handOverNotesFilterOptions 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handOverNotesControllerFindSnapShots(handOverNotesFilterOptions: HandOverNotesFilterOptions, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<Array<object>>;
    public handOverNotesControllerFindSnapShots(handOverNotesFilterOptions: HandOverNotesFilterOptions, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<Array<object>>>;
    public handOverNotesControllerFindSnapShots(handOverNotesFilterOptions: HandOverNotesFilterOptions, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<Array<object>>>;
    public handOverNotesControllerFindSnapShots(handOverNotesFilterOptions: HandOverNotesFilterOptions, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (handOverNotesFilterOptions === null || handOverNotesFilterOptions === undefined) {
            throw new Error('Required parameter handOverNotesFilterOptions was null or undefined when calling handOverNotesControllerFindSnapShots.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<Array<object>>(`${this.configuration.basePath}/hand-over-notes/find`,
            handOverNotesFilterOptions,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param updateHandOverNoteDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handOverNotesControllerModifySnapshot(updateHandOverNoteDto: UpdateHandOverNoteDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<UpdateHandOverNoteResponseDto>;
    public handOverNotesControllerModifySnapshot(updateHandOverNoteDto: UpdateHandOverNoteDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<UpdateHandOverNoteResponseDto>>;
    public handOverNotesControllerModifySnapshot(updateHandOverNoteDto: UpdateHandOverNoteDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<UpdateHandOverNoteResponseDto>>;
    public handOverNotesControllerModifySnapshot(updateHandOverNoteDto: UpdateHandOverNoteDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (updateHandOverNoteDto === null || updateHandOverNoteDto === undefined) {
            throw new Error('Required parameter updateHandOverNoteDto was null or undefined when calling handOverNotesControllerModifySnapshot.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<UpdateHandOverNoteResponseDto>(`${this.configuration.basePath}/hand-over-notes/modify`,
            updateHandOverNoteDto,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handOverNotesControllerRemove(id: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<object>;
    public handOverNotesControllerRemove(id: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<object>>;
    public handOverNotesControllerRemove(id: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<object>>;
    public handOverNotesControllerRemove(id: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling handOverNotesControllerRemove.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.delete<object>(`${this.configuration.basePath}/hand-over-notes/${encodeURIComponent(String(id))}`,

        );
    }

    /**
     * @param patientSummaryFilterOptions 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public handOverNotesControllerStatisticsSnapShot(patientSummaryFilterOptions: PatientSummaryFilterOptions, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public handOverNotesControllerStatisticsSnapShot(patientSummaryFilterOptions: PatientSummaryFilterOptions, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public handOverNotesControllerStatisticsSnapShot(patientSummaryFilterOptions: PatientSummaryFilterOptions, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public handOverNotesControllerStatisticsSnapShot(patientSummaryFilterOptions: PatientSummaryFilterOptions, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        if (patientSummaryFilterOptions === null || patientSummaryFilterOptions === undefined) {
            throw new Error('Required parameter patientSummaryFilterOptions was null or undefined when calling handOverNotesControllerStatisticsSnapShot.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(localVarHttpHeaderAcceptSelected && localVarHttpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/hand-over-notes/statistics-snapshot`,
            patientSummaryFilterOptions,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
