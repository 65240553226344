/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoomEntity } from './roomEntity';
import { AdmissionEntity } from './admissionEntity';


export interface BedEntity { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    bedName?: string;
    cost?: number;
    bedType?: string;
    description?: string;
    isOccupied?: boolean;
    roomId?: number;
    rooms?: RoomEntity;
    admissions?: Array<AdmissionEntity>;
    admission?: AdmissionEntity;
}

