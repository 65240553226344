export * from './adaptersAndMigrations.service';
import { AdaptersAndMigrationsService } from './adaptersAndMigrations.service';
export * from './antenatalBabyMum.service';
import { AntenatalBabyMumService } from './antenatalBabyMum.service';
export * from './antenatalEnrollment.service';
import { AntenatalEnrollmentService } from './antenatalEnrollment.service';
export * from './antenatalQuestionAndAnswer.service';
import { AntenatalQuestionAndAnswerService } from './antenatalQuestionAndAnswer.service';
export * from './appointment.service';
import { AppointmentService } from './appointment.service';
export * from './beneficiary.service';
import { BeneficiaryService } from './beneficiary.service';
export * from './cardCollection.service';
import { CardCollectionService } from './cardCollection.service';
export * from './checkIn.service';
import { CheckInService } from './checkIn.service';
export * from './config.service';
import { ConfigService } from './config.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './immunization.service';
import { ImmunizationService } from './immunization.service';
export * from './immunizationAppointment.service';
import { ImmunizationAppointmentService } from './immunizationAppointment.service';
export * from './immunizationPatient.service';
import { ImmunizationPatientService } from './immunizationPatient.service';
export * from './kiosk.service';
import { KioskService } from './kiosk.service';
export * from './patient.service';
import { PatientService } from './patient.service';
export * from './previousPregnancy.service';
import { PreviousPregnancyService } from './previousPregnancy.service';
export * from './religions.service';
import { ReligionsService } from './religions.service';
export * from './statesAndLgas.service';
import { StatesAndLgasService } from './statesAndLgas.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './subscriptions.service';
import { SubscriptionsService } from './subscriptions.service';
export * from './tribe.service';
import { TribeService } from './tribe.service';
export * from './visitorRequest.service';
import { VisitorRequestService } from './visitorRequest.service';
export * from './vitals.service';
import { VitalsService } from './vitals.service';
export const APIS = [AdaptersAndMigrationsService, AntenatalBabyMumService, AntenatalEnrollmentService, AntenatalQuestionAndAnswerService, AppointmentService, BeneficiaryService, CardCollectionService, CheckInService, ConfigService, GroupService, ImmunizationService, ImmunizationAppointmentService, ImmunizationPatientService, KioskService, PatientService, PreviousPregnancyService, ReligionsService, StatesAndLgasService, StatisticsService, SubscriptionsService, TribeService, VisitorRequestService, VitalsService];
