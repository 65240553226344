export * from './antenatalFollowUpVisit.service';
import { AntenatalFollowUpVisitService } from './antenatalFollowUpVisit.service';
export * from './antenatalPresentPregnancyHistory.service';
import { AntenatalPresentPregnancyHistoryService } from './antenatalPresentPregnancyHistory.service';
export * from './consentForm.service';
import { ConsentFormService } from './consentForm.service';
export * from './deliveryRecord.service';
import { DeliveryRecordService } from './deliveryRecord.service';
export * from './medicalHistory.service';
import { MedicalHistoryService } from './medicalHistory.service';
export * from './preoperativeChecklist.service';
import { PreoperativeChecklistService } from './preoperativeChecklist.service';
export * from './procedures.service';
import { ProceduresService } from './procedures.service';
export * from './proceduresResult.service';
import { ProceduresResultService } from './proceduresResult.service';
export * from './transfusionOrder.service';
import { TransfusionOrderService } from './transfusionOrder.service';
export const APIS = [AntenatalFollowUpVisitService, AntenatalPresentPregnancyHistoryService, ConsentFormService, DeliveryRecordService, MedicalHistoryService, PreoperativeChecklistService, ProceduresService, ProceduresResultService, TransfusionOrderService];
