/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateDoctorInstructionDto { 
    admission_id: number;
    instruction: string;
    status: CreateDoctorInstructionDto.StatusEnum;
    created_by_name: string;
    created_by_id: string;
}
export namespace CreateDoctorInstructionDto {
    export type StatusEnum = 'open' | 'cancelled' | 'done';
    export const StatusEnum = {
        Open: 'open' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        Done: 'done' as StatusEnum
    };
}


