import { Action } from '@ngrx/store';
import * as GenericResultActions from '../actions/genericResult.actions';

// Section 1

export interface GenericResultsIds {
  ids: any;
}
const initialState: GenericResultsIds = {
  ids: [0],
};
// Section 2
export function reducer(state: GenericResultsIds[] = [], action: any) {
  // Section 3
  switch (action.type) {
    case GenericResultActions.ADD_RESULT_IDS:
      return [...state, action.payload];

    case GenericResultActions.REMOVE_RESULT_IDS:
      state.splice(action.payload, 1);
      return state;

    case GenericResultActions.CLEAR_RESULT_IDS:
      state = [];
      console.log(state, 'to clear result');
      return state;

    default:
      return state;
  }
}
