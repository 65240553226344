/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ICreateAppointmentParamsLocationCoordinates } from './iCreateAppointmentParamsLocationCoordinates';


export interface IFetchAppointmentParams { 
    _id?: string;
    clinic?: string;
    practitioner?: string;
    practitionerFirstName?: string;
    practitionerLastName?: string;
    practitionerId?: string;
    date_of_appointment?: string;
    type_of_consultation: IFetchAppointmentParams.TypeOfConsultationEnum;
    status?: IFetchAppointmentParams.StatusEnum;
    patient?: string;
    hospital_number?: string;
    uid?: string;
    comment?: string;
    paymentStatus?: boolean;
    createdByFirstName?: string;
    createdByLastName?: string;
    subscription?: string | null;
    createdById?: string;
    created_at?: string;
    updated_at?: string;
    completed_at?: string;
    bookingType: IFetchAppointmentParams.BookingTypeEnum;
    isEmergency: boolean;
    needsAmbulance?: boolean;
    locationCoordinates?: ICreateAppointmentParamsLocationCoordinates;
    address?: string;
    checkInStatus?: IFetchAppointmentParams.CheckInStatusEnum;
    checkInTime?: string;
    checkOutTime?: string;
}
export namespace IFetchAppointmentParams {
    export type TypeOfConsultationEnum = 'first-time' | 'follow-up' | 'referral' | 'ward-round';
    export const TypeOfConsultationEnum = {
        FirstTime: 'first-time' as TypeOfConsultationEnum,
        FollowUp: 'follow-up' as TypeOfConsultationEnum,
        Referral: 'referral' as TypeOfConsultationEnum,
        WardRound: 'ward-round' as TypeOfConsultationEnum
    };
    export type StatusEnum = 'pending' | 'in-progress' | 'done' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        InProgress: 'in-progress' as StatusEnum,
        Done: 'done' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
    export type BookingTypeEnum = 'self' | 'other';
    export const BookingTypeEnum = {
        Self: 'self' as BookingTypeEnum,
        Other: 'other' as BookingTypeEnum
    };
    export type CheckInStatusEnum = 'pending' | 'checkin' | 'checkout';
    export const CheckInStatusEnum = {
        Pending: 'pending' as CheckInStatusEnum,
        Checkin: 'checkin' as CheckInStatusEnum,
        Checkout: 'checkout' as CheckInStatusEnum
    };
}


