export * from './bills.service';
import { BillsService } from './bills.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './hmoCost.service';
import { HmoCostService } from './hmoCost.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export * from './wallet.service';
import { WalletService } from './wallet.service';
export const APIS = [BillsService, DefaultService, HmoCostService, PaymentService, StatisticsService, WalletService];
