/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GroupedFieldsType = 'sensitivity' | 'microscopy' | 'culture' | 'none';

export const GroupedFieldsType = {
    Sensitivity: 'sensitivity' as GroupedFieldsType,
    Microscopy: 'microscopy' as GroupedFieldsType,
    Culture: 'culture' as GroupedFieldsType,
    None: 'none' as GroupedFieldsType
};

