/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FilterRoomTransferRecordsArgs } from './filterRoomTransferRecordsArgs';


export interface FilterRoomTransferRecordDto { 
    filter: FilterRoomTransferRecordsArgs;
    startDate?: string;
    endDate?: string;
    limit?: number;
    page?: number;
    loadRoomInfo?: boolean;
    loadBedInfo?: boolean;
    loadAdmissionInfo?: boolean;
}

