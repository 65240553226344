/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FilterStatisticsDto { 
    start_date?: string;
    end_date: string;
    receiver_id?: string;
    receiver_username?: string;
    hospital_number?: string;
    department_route?: string;
    request_category?: FilterStatisticsDto.RequestCategoryEnum;
    request_categories?: Array<FilterStatisticsDto.RequestCategoriesEnum>;
    key?: string;
    only_total: boolean;
    status: FilterStatisticsDto.StatusEnum;
}
export namespace FilterStatisticsDto {
    export type RequestCategoryEnum = 'APPOINTMENT' | 'SCAN' | 'LAB' | 'PHARMACY' | 'ALL';
    export const RequestCategoryEnum = {
        Appointment: 'APPOINTMENT' as RequestCategoryEnum,
        Scan: 'SCAN' as RequestCategoryEnum,
        Lab: 'LAB' as RequestCategoryEnum,
        Pharmacy: 'PHARMACY' as RequestCategoryEnum,
        All: 'ALL' as RequestCategoryEnum
    };
    export type RequestCategoriesEnum = 'APPOINTMENT' | 'SCAN' | 'LAB' | 'PHARMACY' | 'ALL';
    export const RequestCategoriesEnum = {
        Appointment: 'APPOINTMENT' as RequestCategoriesEnum,
        Scan: 'SCAN' as RequestCategoriesEnum,
        Lab: 'LAB' as RequestCategoriesEnum,
        Pharmacy: 'PHARMACY' as RequestCategoriesEnum,
        All: 'ALL' as RequestCategoriesEnum
    };
    export type StatusEnum = 'pending' | 'paid' | 'cancelled' | 'paying-online' | 'paid-online' | 'failed-online-payment';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Paid: 'paid' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        PayingOnline: 'paying-online' as StatusEnum,
        PaidOnline: 'paid-online' as StatusEnum,
        FailedOnlinePayment: 'failed-online-payment' as StatusEnum
    };
}


