/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IPreviousPregnancy } from './iPreviousPregnancy';
import { IAntenatalEnrollment } from './iAntenatalEnrollment';
import { ICreateAppointmentParams } from './iCreateAppointmentParams';


export interface ICreateAppointmentEnrollmentPreviousPregnancies { 
    appointment: ICreateAppointmentParams;
    antenatelEnrollment: IAntenatalEnrollment;
    previousPregnancies?: Array<IPreviousPregnancy>;
}

