/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ISubscription { 
    hospital_number: string;
    service_id?: string;
    service_key: string;
    department_route: string;
    service_cost: number;
    percentage_discount?: number;
    follow_up_cost: number;
    current_subscription_count?: number;
    maximum_subscription_count?: number;
    expiration_date: string;
    _id?: string;
    created_at?: string;
    updated_at?: string;
}

