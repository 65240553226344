/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Payment { 
    id: number;
    txType: object;
    paymentMethod?: object;
    online_reference?: string;
    payment_gateway?: Payment.PaymentGatewayEnum;
    invoiceNumber: number;
    amount: number;
    quantity: number;
    itemId?: string;
    itemName: string;
    itemCode?: string;
    payerId?: string;
    payerName?: string;
    payerUsername?: string;
    payerPhone?: string;
    payerSex?: string;
    departmentRoute?: string;
    payerIdNumber?: string;
    appointmentId?: string;
    originalAmount?: number;
    hmoApprovalCode?: string;
    hmoAmount?: number;
    hmoStatus?: Payment.HmoStatusEnum;
    hmoCode?: string;
    payerHmoCode?: string;
    hmoVerifiedById?: string;
    hmoVerifiedByName?: string;
    payerPackage?: string;
    subaccount?: string;
    receiverId?: string;
    receiverName?: string;
    receiverUsername?: string;
    raisedById?: string;
    raisedByName?: string;
    requestId?: string;
    requestCategoryKey?: string;
    requestCategory?: string;
    organizationId?: string;
    medicalHistoryId: string;
    slug?: string;
    bill_id?: number;
    receivedAt?: string;
    created: string;
    updated: string;
    deletedAt?: string;
}
export namespace Payment {
    export type PaymentGatewayEnum = 'paystack' | 'accelerex' | 'flutterwave';
    export const PaymentGatewayEnum = {
        Paystack: 'paystack' as PaymentGatewayEnum,
        Accelerex: 'accelerex' as PaymentGatewayEnum,
        Flutterwave: 'flutterwave' as PaymentGatewayEnum
    };
    export type HmoStatusEnum = 'approved' | 'cancelled' | 'default' | 'in-progress';
    export const HmoStatusEnum = {
        Approved: 'approved' as HmoStatusEnum,
        Cancelled: 'cancelled' as HmoStatusEnum,
        Default: 'default' as HmoStatusEnum,
        InProgress: 'in-progress' as HmoStatusEnum
    };
}


