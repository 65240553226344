/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DeliveryType = 'vaginal' | 'cs' | 'other';

export const DeliveryType = {
    Vaginal: 'vaginal' as DeliveryType,
    Cs: 'cs' as DeliveryType,
    Other: 'other' as DeliveryType
};

