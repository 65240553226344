/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IAdapterResult { 
    error: IAdapterResult.ErrorEnum;
    message?: string;
    details?: any | null;
}
export namespace IAdapterResult {
    export type ErrorEnum = false | true;
    export const ErrorEnum = {
        False: false as ErrorEnum,
        True: true as ErrorEnum
    };
}


