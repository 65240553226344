/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IProcedureResultFiles } from './iProcedureResultFiles';
import { IProceduresResultsDescriptionOfProcedure } from './iProceduresResultsDescriptionOfProcedure';
import { IVitals } from './iVitals';
import { IProcedureResultFields } from './iProcedureResultFields';


export interface IProceduresResults { 
    _id?: string | null;
    serviceId?: string;
    serviceKey?: string;
    hospitalNumber: string;
    appointmentId: string;
    patientId?: string;
    patientHmoCode?: string;
    enteredByUserName?: string;
    enteredById?: string | null;
    enteredByFullName?: string;
    results?: Array<IProcedureResultFields>;
    files?: Array<IProcedureResultFiles>;
    descriptionOfProcedure?: IProceduresResultsDescriptionOfProcedure;
    complications?: Array<string>;
    vascularAcessCare?: Array<string>;
    intraDialysisVitalsSigns?: Array<IVitals>;
    postDialysisVitalsSign?: IVitals;
}

