/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CardCollectionFilter { 
    start_date?: string;
    end_date?: string;
    hospital_number?: string;
    printed_by_name?: string;
    printed_by_id?: string;
    searchString?: string;
}

