/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PartialIFetchedAppointment } from './partialIFetchedAppointment';


export interface AppointmentFilter { 
    filter: PartialIFetchedAppointment;
    start_date?: string;
    end_date?: string;
    multiple_status?: Array<AppointmentFilter.MultipleStatusEnum>;
    multiple_type_of_consultation?: Array<AppointmentFilter.MultipleTypeOfConsultationEnum>;
    filterByAppointmentDate?: AppointmentFilter.FilterByAppointmentDateEnum;
    page?: number;
    limit?: number;
    order?: string;
    select?: string;
    populate?: boolean;
}
export namespace AppointmentFilter {
    export type MultipleStatusEnum = 'pending' | 'in-progress' | 'done' | 'cancelled';
    export const MultipleStatusEnum = {
        Pending: 'pending' as MultipleStatusEnum,
        InProgress: 'in-progress' as MultipleStatusEnum,
        Done: 'done' as MultipleStatusEnum,
        Cancelled: 'cancelled' as MultipleStatusEnum
    };
    export type MultipleTypeOfConsultationEnum = 'first-time' | 'follow-up' | 'referral' | 'ward-round';
    export const MultipleTypeOfConsultationEnum = {
        FirstTime: 'first-time' as MultipleTypeOfConsultationEnum,
        FollowUp: 'follow-up' as MultipleTypeOfConsultationEnum,
        Referral: 'referral' as MultipleTypeOfConsultationEnum,
        WardRound: 'ward-round' as MultipleTypeOfConsultationEnum
    };
    export type FilterByAppointmentDateEnum = 'yes' | 'no';
    export const FilterByAppointmentDateEnum = {
        Yes: 'yes' as FilterByAppointmentDateEnum,
        No: 'no' as FilterByAppointmentDateEnum
    };
}


