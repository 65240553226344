/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BabySex } from './babySex';
import { DeliveryType } from './deliveryType';


export interface IDeliveryRecord { 
    _id?: string;
    appointmentId?: string;
    enrollmentId: string;
    summary?: string;
    mother_hospital_number?: string;
    child_hospital_number?: string;
    delivery_time?: string;
    birth_weight?: string;
    sex?: BabySex;
    delivery_type?: DeliveryType;
    created_at?: string;
    updated_at?: string;
}

