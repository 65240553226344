<!-- <div style=" overflow: hidden; ">

    <div style="margin-top: 8%;">
        <img src="../../assets/images/logo.png" alt="logo" width="10%" class="center mb-5">

        <div class="jumbotron jumbotron-fluid mb-0 ">
            <div class="container">
                <div class="row justify-content-center text-center">
                    <div class="col-md-10 col-lg-6">
                        <h1 class="display-5 fs-1 mb-3 text-blue">My Home CLinic Lab And Radiology</h1>

                        <p class="lead">Making Nigeria a global healthcare destination through provision of world-class healthcare services powered by superior technology in medicine.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row mt-5" style="margin-top: 500px;">
        <div></div>
        <div class="row">
            <div class="col-md-6 " style="text-align: right;">
                <button routerLink="/lab/requests" type="button" class="btn btn-lg  btn-outline-info">LABORATORY MODULE</button>

            </div>
            <div class="col-md-6">
                <button routerLink="/radiology" type="button" class="btn btn-lg btn-outline-info">RADIOLOGY MODULE</button>

            </div>
        </div>
        <div></div>

    </div>
</div> -->





<main class="main">
    <section class="landing">
        <div class="landing-text ">
            <h1>My Home CLinic Lab And Radiology</h1>


            <p>
                Making Nigeria a global healthcare destination through provision of world-class healthcare services
                powered by superior technology in medicine.
            </p>
            <div class="row ">
                <div class="col">
                    <a routerLink="/lab" class="btn btn-lg">LABORATORY MODULE</a>
                </div>
                <div class="col">
                    <a routerLink="/radiology" class="btn btn-lg">RADIOLOGY MODULE</a>
                </div>
            </div>
        </div>
        <div class="landing-image">
            <img src="../../assets/images/undraw_medicine_b-1-ol.svg" alt="Working Illustration" />
        </div>
    </section>
</main>