/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IVaccine } from './iVaccine';


export interface IImmunizationAppointmentWithVaccine { 
    patientFirstName?: string;
    patientLastName?: string;
    sex?: IImmunizationAppointmentWithVaccine.SexEnum;
    hospitalNumber: string;
    email?: string;
    phone?: string;
    appointmentDate: string;
    patientId: string | null;
    patientDateOfBirth?: string;
    patientAgeInWeeks?: number;
    status?: IImmunizationAppointmentWithVaccine.StatusEnum;
    bookedByStaffId: string;
    bookedByStaffFirstName: string;
    bookedByStaffLastName: string;
    patientHmoCode?: string;
    hmoCode?: string;
    hmoId?: string;
    hmoName?: string;
    vaccines: Array<IVaccine>;
}
export namespace IImmunizationAppointmentWithVaccine {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type StatusEnum = 'pending' | 'completed' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


