/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateWardRoundDto { 
    clinic?: string;
    practitioner?: string;
    practitionerFirstName?: string;
    practitionerLastName?: string;
    practitionerId: string;
    patientId: string;
    admissionId: string;
    patientHmoCode?: string;
    hmoCode?: string;
    email?: string;
    phone?: string;
    packageName?: string;
    hospitalNumber: string;
    sex?: CreateWardRoundDto.SexEnum;
    comment?: string;
    patientFirstName?: string;
    patientLastName?: string;
    createdByFirstName?: string;
    createdByLastName?: string;
    createdById: string;
    doctorsInstruction?: string;
    hospitalBranch?: string;
    age?: number;
    isEmergency?: boolean;
    address?: string;
    checkInStatus?: CreateWardRoundDto.CheckInStatusEnum;
    checkInTime?: string;
    checkOutTime?: string;
}
export namespace CreateWardRoundDto {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type CheckInStatusEnum = 'pending' | 'checkin' | 'checkout';
    export const CheckInStatusEnum = {
        Pending: 'pending' as CheckInStatusEnum,
        Checkin: 'checkin' as CheckInStatusEnum,
        Checkout: 'checkout' as CheckInStatusEnum
    };
}


