/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FetchObservationDto { 
    id?: number;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    date?: string;
    temperature?: string;
    appointmentId?: string;
    temperatureUnit?: string;
    pulse?: string;
    pulseUnit?: string;
    resp?: string;
    respUnit?: string;
    spo2?: string;
    spo2Unit?: string;
    weight?: string;
    weightUnit?: string;
    height?: string;
    heightUnit?: string;
    bpCystolicUnit?: string;
    bpCystolicValue?: string;
    bpDiastolicUnit?: string;
    bpDiastolicValue?: string;
    remarks?: string;
    staffId?: string;
    staffName?: string;
    patientName?: string;
    slug?: string;
    patientId?: string;
    doctorsReview?: string;
    admissionNumber?: number;
}

