/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DrugAdministrationsEntity } from './drugAdministrationsEntity';


export interface UpdateNurseRemarkDto { 
    patientId?: string;
    hospitalNumber?: string;
    admissionNumber?: string;
    prescriptionId?: string;
    dateTimeToAdministerDrug?: string;
    patientFullName?: string;
    staffId?: string;
    staffFullName?: string;
    remarks?: UpdateNurseRemarkDto.RemarksEnum;
    drugAdminId?: number;
    drugAdmin?: DrugAdministrationsEntity;
}
export namespace UpdateNurseRemarkDto {
    export type RemarksEnum = 'Patient not on bed' | 'Patient declined' | 'Out of stock' | 'Patient has the drug';
    export const RemarksEnum = {
        PatientNotOnBed: 'Patient not on bed' as RemarksEnum,
        PatientDeclined: 'Patient declined' as RemarksEnum,
        OutOfStock: 'Out of stock' as RemarksEnum,
        PatientHasTheDrug: 'Patient has the drug' as RemarksEnum
    };
}


