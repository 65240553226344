/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientDetailsForHandOverNotes } from './patientDetailsForHandOverNotes';


export interface HandOverNotesFilterOptions { 
    limit?: number;
    page?: number;
    start_date?: string;
    end_date?: string;
    /**
     * property to use when fetching dates
     */
    filter_property?: HandOverNotesFilterOptions.FilterPropertyEnum;
    patientData?: PatientDetailsForHandOverNotes | null;
    id?: number | null;
}
export namespace HandOverNotesFilterOptions {
    export type FilterPropertyEnum = 'snapShotStartDateTime' | 'createdAt';
    export const FilterPropertyEnum = {
        SnapShotStartDateTime: 'snapShotStartDateTime' as FilterPropertyEnum,
        CreatedAt: 'createdAt' as FilterPropertyEnum
    };
}


