/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PatientDetailsForHandOverNotes } from './patientDetailsForHandOverNotes';


export interface CreateHandOverNoteDto { 
    snapShotStartDateTime?: string;
    snapShotEndDateTime?: string;
    staffFullName?: string;
    staffId?: string;
    snapshotNote?: string;
    patientsData: Array<PatientDetailsForHandOverNotes>;
}

