/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateAdmissionDto { 
    patientName: string;
    patientId: string;
    age?: string;
    image?: string;
    initialDiagnosis?: object;
    currentDiagnosis?: string;
    gender: CreateAdmissionDto.GenderEnum;
    dateOfBirth: string;
    hospitalNumber: string;
    admissionNumber?: number;
    hmoCode?: string;
    patientHmoNumber?: string;
    appointmentId: string;
    admittedById: string;
    admittedByName: string;
    dischargedBy?: string;
    dischargedByName?: string;
    modeOfDischarge?: CreateAdmissionDto.ModeOfDischargeEnum;
    modeOfAdmission: CreateAdmissionDto.ModeOfAdmissionEnum;
    admissionStatus: CreateAdmissionDto.AdmissionStatusEnum;
    phone: string;
    email?: string;
    dischargeDate?: string;
    expectedDischargedDate?: string;
    admittedDate?: string;
    slug?: string;
    notes: string;
    bedId?: number;
    priority?: CreateAdmissionDto.PriorityEnum;
    priorityWeight?: number;
}
export namespace CreateAdmissionDto {
    export type GenderEnum = 'Male' | 'Female' | 'Other';
    export const GenderEnum = {
        Male: 'Male' as GenderEnum,
        Female: 'Female' as GenderEnum,
        Other: 'Other' as GenderEnum
    };
    export type ModeOfDischargeEnum = 'Normal' | 'Against Medical Advice' | 'transfered out' | 'refered out';
    export const ModeOfDischargeEnum = {
        Normal: 'Normal' as ModeOfDischargeEnum,
        AgainstMedicalAdvice: 'Against Medical Advice' as ModeOfDischargeEnum,
        TransferedOut: 'transfered out' as ModeOfDischargeEnum,
        ReferedOut: 'refered out' as ModeOfDischargeEnum
    };
    export type ModeOfAdmissionEnum = 'Normal' | 'Transfered In';
    export const ModeOfAdmissionEnum = {
        Normal: 'Normal' as ModeOfAdmissionEnum,
        TransferedIn: 'Transfered In' as ModeOfAdmissionEnum
    };
    export type AdmissionStatusEnum = 'Admitted' | 'Discharged' | 'Unknown';
    export const AdmissionStatusEnum = {
        Admitted: 'Admitted' as AdmissionStatusEnum,
        Discharged: 'Discharged' as AdmissionStatusEnum,
        Unknown: 'Unknown' as AdmissionStatusEnum
    };
    export type PriorityEnum = 'Critical' | 'Serious' | 'Fair' | 'Good' | 'Undetermined';
    export const PriorityEnum = {
        Critical: 'Critical' as PriorityEnum,
        Serious: 'Serious' as PriorityEnum,
        Fair: 'Fair' as PriorityEnum,
        Good: 'Good' as PriorityEnum,
        Undetermined: 'Undetermined' as PriorityEnum
    };
}


