/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VisitorRequestStatusType = 'pending' | 'ongoing' | 'completed' | 'cancelled' | 'expired';

export const VisitorRequestStatusType = {
    Pending: 'pending' as VisitorRequestStatusType,
    Ongoing: 'ongoing' as VisitorRequestStatusType,
    Completed: 'completed' as VisitorRequestStatusType,
    Cancelled: 'cancelled' as VisitorRequestStatusType,
    Expired: 'expired' as VisitorRequestStatusType
};

