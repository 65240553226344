/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IFiles } from './iFiles';
import { IInvestigationsSummary } from './iInvestigationsSummary';
import { IDiagnosesSummary } from './iDiagnosesSummary';
import { IDetails } from './iDetails';
import { IMedicalHistoryBloodPressure } from './iMedicalHistoryBloodPressure';
import { IQuestionsSummary } from './iQuestionsSummary';


export interface IMedicalHistory { 
    _id?: string | null;
    hospitalNumber: string;
    firstName: string;
    lastName: string;
    otherName?: string;
    sex: IMedicalHistory.SexEnum;
    phone?: string;
    email?: string;
    qa?: Array<string>;
    cqa?: Array<string>;
    vitalsId?: string;
    appointmentId?: string;
    admissionId?: string;
    temperature?: IDetails;
    weight?: IDetails;
    bloodPressure?: IMedicalHistoryBloodPressure;
    height?: IDetails;
    bmi?: IDetails;
    headCircumference?: IDetails;
    pulseRate?: IDetails;
    respiratoryRate?: IDetails;
    sp02?: IDetails;
    timeTaken?: string;
    recordedByUsername?: string;
    recordedByFirstName?: string;
    recordedByLastName?: string;
    recordedByDesignation?: string;
    recordedById?: string;
    mainComplaints?: Array<IQuestionsSummary>;
    examinations?: Array<IQuestionsSummary>;
    differentials?: Array<IDiagnosesSummary>;
    otherFindings?: Array<IDiagnosesSummary>;
    otherPositives?: Array<IDiagnosesSummary>;
    provisionalDiagnosis?: Array<IDiagnosesSummary>;
    investigations?: Array<IInvestigationsSummary>;
    finalDiagnosis?: Array<IDiagnosesSummary>;
    prescriptions?: Array<string>;
    doctorsNote?: string;
    procedures?: Array<string> | Array<any> | null;
    staffId?: string;
    staffUsername?: string;
    staffFirstName?: string;
    staffLastName?: string;
    staffEmail?: string;
    staffSignature?: string;
    lastMedicalHistoryId?: string;
    files?: Array<IFiles>;
    notes?: string;
    status?: IMedicalHistory.StatusEnum;
    patient_hmo_code?: string;
    hmo_code?: string;
    hmo_name?: string;
    organization_id?: string;
    dateCreated?: string;
    dateUpdated?: string;
    __v?: number;
}
export namespace IMedicalHistory {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type StatusEnum = 'pending' | 'completed' | 'cancelled';
    export const StatusEnum = {
        Pending: 'pending' as StatusEnum,
        Completed: 'completed' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum
    };
}


