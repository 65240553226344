import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LabModulesModule } from './laboratory-module/lab-modules.module';
import { MainComponent } from './main-page-component/main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'lab',
    loadChildren: () =>
      import('./laboratory-module/lab-modules.module').then(
        (m) => m.LabModulesModule
      ),
  },
  {
    path: 'radiology',
    loadChildren: () =>
      import('./radiology-modules/radiology-modules.module').then(
        (m) => m.RadiologyModulesModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Your router options here:
      canceledNavigationResolution: 'replace', // Example
      // ... other options
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
