import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { GenericResultsIds } from '../reducers/genericResults.reducers';

// Section 2
export const ADD_RESULT_IDS = '[RESULT] Add';
export const REMOVE_RESULT_IDS = '[RESULT] Remove';
export const CLEAR_RESULT_IDS = '[RESULT] Clear';

// Section 3
export class AddResultIds implements Action {
  readonly type = ADD_RESULT_IDS;

  constructor(public payload: GenericResultsIds) {}
}

export class RemoveResultIds implements Action {
  readonly type = REMOVE_RESULT_IDS;

  constructor(public payload: number) {}
}

export class ClearResultIds implements Action {
  readonly type = CLEAR_RESULT_IDS;

  constructor() {}
}

// Section 4
export type Actions = AddResultIds | RemoveResultIds | ClearResultIds;
