/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IPatientImmunization { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    patientId: string | null;
    hospitalNumber: string;
    patientDateOfBirth?: string;
    patientAgeInWeeks?: number;
    sex?: IPatientImmunization.SexEnum;
    appointmentId?: string | null;
    immunizationId?: string | null;
    immunizationUniqueCode?: string;
    immunizationCost?: number;
    dateTaken?: string;
    status?: IPatientImmunization.StatusEnum;
    paymentStatus?: IPatientImmunization.PaymentStatusEnum;
    doneByStaffId?: string;
    doneByStaffFirstName?: string;
    doneByStaffLastName?: string;
    hasBeenReceived?: IPatientImmunization.HasBeenReceivedEnum;
    immunizationAppointmentId?: string | null;
    created_at?: string;
    updated_at?: string;
}
export namespace IPatientImmunization {
    export type SexEnum = 'male' | 'female';
    export const SexEnum = {
        Male: 'male' as SexEnum,
        Female: 'female' as SexEnum
    };
    export type StatusEnum = 'inprogress' | 'received' | 'future';
    export const StatusEnum = {
        Inprogress: 'inprogress' as StatusEnum,
        Received: 'received' as StatusEnum,
        Future: 'future' as StatusEnum
    };
    export type PaymentStatusEnum = 'paying' | 'notpaid' | 'paid' | 'bill-reversed' | 'bill-deleted';
    export const PaymentStatusEnum = {
        Paying: 'paying' as PaymentStatusEnum,
        Notpaid: 'notpaid' as PaymentStatusEnum,
        Paid: 'paid' as PaymentStatusEnum,
        BillReversed: 'bill-reversed' as PaymentStatusEnum,
        BillDeleted: 'bill-deleted' as PaymentStatusEnum
    };
    export type HasBeenReceivedEnum = false | true;
    export const HasBeenReceivedEnum = {
        False: false as HasBeenReceivedEnum,
        True: true as HasBeenReceivedEnum
    };
}


