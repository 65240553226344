/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VisitorRequestStatusType } from './visitorRequestStatusType';


/**
 * Make all properties in T optional
 */
export interface PartialIVisitorRequest { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    patientHospitalNumber?: string;
    visitorFullName?: string;
    numberOfVisitors?: number;
    visitorCode?: string;
    expiryTime?: string;
    visitorTimeIn?: string;
    visitorTimeOut?: string;
    status?: VisitorRequestStatusType;
    created_at?: string;
    updated_at?: string;
}

