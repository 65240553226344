/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IPatientAppointmentsAndExpirations } from './iPatientAppointmentsAndExpirations';
import { IHmoDetails } from './iHmoDetails';
import { INextOfKinDetails } from './iNextOfKinDetails';
import { IPatientAdditionalHistories } from './iPatientAdditionalHistories';
import { IBeneficiary } from './iBeneficiary';


/**
 * Make all properties in T optional
 */
export interface PartialIPatient { 
    _id?: string;
    __v?: number;
    nextOfKin?: INextOfKinDetails;
    hmo?: IHmoDetails;
    slug?: string;
    old_hospital_number?: string;
    isAdmitted?: boolean;
    firebaseId?: string;
    additionalHistories?: Array<IPatientAdditionalHistories>;
    appointmentsAndExpirations?: Array<IPatientAppointmentsAndExpirations>;
    patches?: Array<string>;
    hasPrintedCard?: boolean;
    printedCardAt?: string;
    expirationDate?: string;
    profilePicture?: string;
    lastCheckInTime?: string;
    created_at?: string;
    updated_at?: string;
    error?: string;
    title?: string;
    fname?: string;
    lname?: string;
    oname?: string;
    sex?: string;
    email?: string;
    phoneNumber?: string;
    altPhoneNumber?: string;
    username?: string;
    password?: string;
    pin?: string;
    uid?: string;
    failed_pin_attempts?: number;
    hospital_number?: string;
    registrationType?: string;
    familyId?: string;
    maritalStatus?: string;
    stateOfOrigin?: string;
    localGovernment?: string;
    date_of_birth?: string;
    isConfirmedDateOfBirth?: boolean;
    address?: string;
    tribe?: string;
    religion?: string;
    occupation?: string;
    imageUrl?: string;
    genotype?: string;
    bloodGroup?: string;
    beneficiaryId?: string | IBeneficiary | null;
    userGroupId?: string;
    files?: { [key: string]: string; };
    isAlive?: boolean;
    registeredByFirstName?: string;
    registeredByLastName?: string;
    registeredByUserName?: string;
    enrolleeNumber?: string;
    patientSignature?: string;
    paymentStatus?: boolean;
}

