/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdmissionEntity } from './admissionEntity';


export interface CreateInvestigationsSampleDto { 
    patientId: string;
    hospitalNUmber: string;
    admissionNumber: string;
    patientFullName: string;
    staffId: string;
    staffFullName: string;
    investigationTitle?: string;
    investigationKey?: string;
    investigationId?: string;
    sampleType: CreateInvestigationsSampleDto.SampleTypeEnum;
    sampleCode: string;
    quantity?: string;
    unit?: string;
    admissionId?: number;
    admission: AdmissionEntity;
}
export namespace CreateInvestigationsSampleDto {
    export type SampleTypeEnum = 'blood' | 'urine' | 'stool' | 'others';
    export const SampleTypeEnum = {
        Blood: 'blood' as SampleTypeEnum,
        Urine: 'urine' as SampleTypeEnum,
        Stool: 'stool' as SampleTypeEnum,
        Others: 'others' as SampleTypeEnum
    };
}


