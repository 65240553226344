/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Make all properties in T optional
 */
export interface PartialIAntenatalEnrollment { 
    _id?: string;
    patientFirstName?: string;
    patientLastName?: string;
    patientId?: string | null;
    hospitalNumber?: string;
    patientDateOfBirth?: string;
    partnersName?: string;
    partnersOccupation?: string;
    partnersEmployer?: string;
    /**
     * LMP
     */
    lastMenstrualPeriod?: string;
    /**
     * EDD
     */
    expectedDeliveryDate?: string;
    isExpired?: boolean;
    expirationDate?: string;
    expirationReason?: string;
    totalPreviousPregnancies?: number;
    numberOfLivingChildren?: number;
    durationOfPregnancyInWeeks?: string;
    pregnancyPackageName?: string;
    pregnancyPackageId?: number;
    pregnancyPackageKey?: string;
    pregnancyPackageCost?: number;
    appointmentId?: string | null;
    practitioner?: string;
    practitionerFirstName?: string;
    practitionerLastName?: string;
    practitionerId?: string;
    slug?: string;
    created_at?: string;
    updated_at?: string;
}

