import { createAction, props } from '@ngrx/store';
import { LabRequest } from 'src/core/api/v1/lab-api';

export const storeLabRequests = createAction(
  'StoreLabRequests [LabRequestsComponent]',
  props<{ requests: LabRequest[] }>()
);

export const addLabRequest = createAction(
  'AddLabRequst',
  props<{ request: LabRequest }>()
);

export const addLabRequests = createAction(
  'AddLabRequst',
  props<{ requests: LabRequest[] }>()
);

export const updateLabRequest = createAction(
  'UpdateLabRequest',
  props<{ request: Partial<LabRequest> }>()
);

export const updateLabRequests = createAction(
  'UpdateLabRequest',
  props<{ requests: Partial<LabRequest>[] }>()
);

export const removeLabRequest = createAction(
  'RemoveLabRequest',
  props<{ request: LabRequest }>()
);
