/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Make all properties in T optional
 */
export interface PartialIPreviousPregnancy { 
    _id?: string | null;
    patientFirstName?: string;
    patientLastName?: string;
    patientId?: string | null;
    hospitalNumber?: string;
    dateOfBirth?: string;
    durationInWeeks?: string;
    birthWeightInKg?: number;
    birthWeightInPounds?: number;
    /**
     * Baby Sex A/SA, NND - More clarity might be needed
     */
    babySex?: string;
    slug?: string;
    pregnancyLabourPuerperium?: string;
    created_at?: string;
    updated_at?: string;
}

