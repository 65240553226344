import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SideBarManager } from './utils/sidebar-manager';
// import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
// import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {
  KeycloakService,
  KeycloakAngularModule,
  KeycloakEventType,
} from 'keycloak-angular';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterViewInit {
  [x: string]: any;
  title = 'home-clinic-lab';

  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  //   .pipe(
  //     map(result => result.matches),
  //     shareReplay()
  //   );

  // constructor(private breakpointObserver: BreakpointObserver) {}

  hasOpened!: boolean;
  navStyle = {};
  @ViewChild('presenceChecker') presenceChecker!: TemplateRef<any>;
  @ViewChild('leftSystem') leftSystem!: TemplateRef<any>;

  counter: number = 20;
  currentCounter: number = 20;
  isHere: boolean = true;
  tokenLifeTime = 30;
  dialogIsOpened = false;

  userActivity: any;
  userInactive: Subject<any> = new Subject();

  awayTime: string = '';

  dialogRef?: MatDialogRef<any>;
  matDialogRef?: MatDialogRef<any>;

  constructor(
    private sideBarManager: SideBarManager,
    private keycloakService: KeycloakService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.keycloakService.keycloakEvents$.subscribe({
      next: (e) => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          this.keycloakService.updateToken(this.tokenLifeTime * 60);
        }
      },
    });

    this.userInactive.subscribe(() => {
      this.dialogIsOpened = true;

      this.isHere = false;
      this.currentCounter = this.counter;
      this.dialogRef = this.dialog.open(this.presenceChecker, {
        minHeight: 400,
        minWidth: 400,
      });
      this.dialogRef.afterClosed().subscribe({
        next: (res) => {
          this.dialogIsOpened = false;
        },
      });
      let it = setInterval(() => {
        if (this.isHere) {
          this.keycloakService.updateToken(this.tokenLifeTime * 60);
          this.dialogRef?.close();
          clearInterval(it);
        } else if (this.currentCounter <= 0) {
          this.dialogRef?.close();
          localStorage.setItem('awayFromSystem', 'true');
          localStorage.setItem('awayTime', new Date().toISOString());
          this.keycloakService.logout();
          clearInterval(it);
        }
        this.currentCounter--;
      }, 1000);
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => {
      if (!this.dialogIsOpened) {
        this.userInactive.next(undefined);
      }
    }, this.tokenLifeTime * 60 * 1000);
    // }, this.tokenLifeTime * 1000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngAfterViewInit(): void {
    if (localStorage.getItem('awayFromSystem')) {
      setTimeout(() => {
        this.awayTime = localStorage.getItem('awayTime') ?? '';
        this.matDialogRef = this.dialog.open(this.leftSystem, {
          minHeight: 400,
          minWidth: 400,
        });
        localStorage.removeItem('awayFromSystem');
      }, 5000);
    }
  }
  isOpened() {
    this.hasOpened = true;
    this.navStyle = { width: '86%' };
    this.sideBarManager.setIsOpen(this.hasOpened);
  }

  isClosed() {
    this.hasOpened = false;
    this.navStyle = { width: '100%' };
    this.sideBarManager.setIsOpen(this.hasOpened);
  }

  get currentYear() {
    return new Date().getFullYear();
  }
}
