/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateHmoCostDto { 
    hmo_code: string;
    item_code: string;
    hmo_name: string;
    hmo_cost: number;
    item_name: string;
    item_type: CreateHmoCostDto.ItemTypeEnum;
    org_id: string;
    status: CreateHmoCostDto.StatusEnum;
    percentage_covered: number;
    needs_validation: boolean;
    mark_for_review: boolean;
}
export namespace CreateHmoCostDto {
    export type ItemTypeEnum = 'service' | 'product';
    export const ItemTypeEnum = {
        Service: 'service' as ItemTypeEnum,
        Product: 'product' as ItemTypeEnum
    };
    export type StatusEnum = 'active' | 'disabled';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Disabled: 'disabled' as StatusEnum
    };
}


