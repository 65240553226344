/**
 * home-clinic-lab-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 3.0.8
 * Contact: balocodes@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LabResultDataRangeChildren } from './labResultDataRangeChildren';
import { LabResultDataFiles } from './labResultDataFiles';


export interface LabResultDataValues { 
    range_children?: LabResultDataRangeChildren;
    range_female?: LabResultDataRangeChildren;
    range_male?: LabResultDataRangeChildren;
    files?: LabResultDataFiles;
    range?: Array<number | string>;
    status?: string;
    title?: string;
    fileUrl?: string;
    grouped_fields?: string;
    fieldType?: string;
    value: any | null;
    key: string;
}

