/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Result object returned by UpdateQueryBuilder execution.
 */
export interface UpdateResult { 
    /**
     * Raw SQL result returned by executed query.
     */
    raw: any | null;
    /**
     * Number of affected rows/documents Not all drivers support this
     */
    affected?: number;
    /**
     * Generated values returned by a database. Has entity-like structure (not just column database name and values).
     */
    generatedMaps: Array<{ [key: string]: any; }>;
}

