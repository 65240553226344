/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DoctorInstructionFilter { 
    startDate?: string;
    endDate?: string;
    admission_id?: number;
    status?: DoctorInstructionFilter.StatusEnum;
}
export namespace DoctorInstructionFilter {
    export type StatusEnum = 'open' | 'cancelled' | 'done';
    export const StatusEnum = {
        Open: 'open' as StatusEnum,
        Cancelled: 'cancelled' as StatusEnum,
        Done: 'done' as StatusEnum
    };
}


