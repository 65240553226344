/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Make all properties in T optional
 */
export interface PartialIFollowUpVisit { 
    _id?: string | null;
    appointmentId?: string | null;
    enrollmentId?: string | null;
    heightOfFundus?: number;
    weight?: number;
    relationOfPresentingPartOfBrim?: string;
    urine?: string;
    oedema?: string;
    presentationAndPosition?: string;
    foetalHeart?: string;
    bp?: string;
    hb?: number;
    remarks?: string;
    returnDate?: string;
    created_at?: string;
    updated_at?: string;
}

