/**
 * drsal-records-service
 * Backend of Save A Life Hospital\'s Patients Record Service
 *
 * The version of the OpenAPI document: 3.8.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type GenderEnum = 'male' | 'Male' | 'female' | 'Female' | 'total';

export const GenderEnum = {
    Male: 'male' as GenderEnum,
    // Male: 'Male' as GenderEnum,
    Female: 'female' as GenderEnum,
    // Female: 'Female' as GenderEnum,
    Total: 'total' as GenderEnum
};

