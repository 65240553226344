import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './laboratory-module/material.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
// import {  MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
// import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { initializer } from './AppInit';

// import { LabRequestModule } from './laboratory-modules/lab-request/lab-request.module';
import { FormsModule } from '@angular/forms';
import { MainComponent } from './main-page-component/main.component';
import { RouterModule, Routes } from '@angular/router';
// import * as apiModuleServiceService from './core/service-service/v1/';
import { environment } from 'src/environments/environment';
import { BASE_PATH as Hospital_BASE_PATH } from 'src/core/api/v1/service-api';
import { BASE_PATH as labService_BASE_PATH } from 'src/core/api/v1/lab-api';
import { BASE_PATH as records_BASE_PATH } from 'src/core/api/v1/records-api';
import { BASE_PATH as medical_history_BASE_PATH } from 'src/core/api/v1/medical-history';
import { BASE_PATH as finance_BASE_PATH } from 'src/core/api/v1/finance';
import { BASE_PATH as admission_BASE_PATH } from 'sdk/admission/v1';

import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular';
import { DatePipe } from '@angular/common';
import { AuthService } from './service/auth.service';
import { StoreModule } from '@ngrx/store';
import { SocketService } from './service/socket.service';
import { reducer } from './laboratory-module/reducers/genericResults.reducers';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [AppComponent, MainComponent],
  imports: [
    BrowserModule,
    StoreModule.forRoot({
      resultIds: reducer,
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatSidenavModule,
    EditorModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    PdfViewerModule,
    // LabRequestModule,
    FormsModule,
    RouterModule,
    KeycloakAngularModule,

    // ApiModule.forRoot(apiConfigFactory)
  ],
  providers: [
    KeycloakService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      multi: true,
      deps: [SocketService],
    },
    AuthService,
    {
      provide: Hospital_BASE_PATH,
      useValue: environment.baseUrlServiceService,
    },
    {
      provide: labService_BASE_PATH,
      useValue: environment.baseUrlLabService,
    },
    {
      provide: records_BASE_PATH,
      useValue: environment.baseUrlRecordsService,
    },
    {
      provide: medical_history_BASE_PATH,
      useValue: environment.baseUrlMedical_history_service,
    },
    {
      provide: finance_BASE_PATH,
      useValue: environment.baseUrlFinanceService,
    },
    {
      provide: admission_BASE_PATH,
      useValue: environment.baseUrlAdmissionService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
