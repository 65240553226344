/**
 * medical-service-salh
 * Medical History As A Service
 *
 * The version of the OpenAPI document: 1.6.0
 * Contact: j.i.obiagba2016@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IResult { 
    error: IResult.ErrorEnum;
    details?: any | null;
    err?: any | null;
    message?: string;
}
export namespace IResult {
    export type ErrorEnum = true | false;
    export const ErrorEnum = {
        True: true as ErrorEnum,
        False: false as ErrorEnum
    };
}


