/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PatientDetailsForHandOverNotes { 
    note?: string;
    patientId?: string;
    hospitalNumber?: string;
    patientState?: PatientDetailsForHandOverNotes.PatientStateEnum;
    admissionNumber?: string;
    timeTaken?: string;
    roomId?: string;
    roomName?: string;
    roomGroupId?: string;
    roomGroupName?: string;
    transferType?: PatientDetailsForHandOverNotes.TransferTypeEnum;
    transferNote?: string;
}
export namespace PatientDetailsForHandOverNotes {
    export type PatientStateEnum = 'stable' | 'critical' | 'deceased' | 'icu' | 'had_procedure';
    export const PatientStateEnum = {
        Stable: 'stable' as PatientStateEnum,
        Critical: 'critical' as PatientStateEnum,
        Deceased: 'deceased' as PatientStateEnum,
        Icu: 'icu' as PatientStateEnum,
        HadProcedure: 'had_procedure' as PatientStateEnum
    };
    export type TransferTypeEnum = 'IN' | 'OUT';
    export const TransferTypeEnum = {
        In: 'IN' as TransferTypeEnum,
        Out: 'OUT' as TransferTypeEnum
    };
}


