/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PatientSummaryFilterOptions { 
    limit?: number;
    page?: number;
    sort?: object;
    searchQuery?: string;
    hospitalNumber?: string;
    admissionNumber?: number;
    start_date?: string;
    end_date?: string;
    bed_ids?: Array<number>;
}

