/**
 * services-service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.9.7
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * From T, pick a set of properties whose keys are in the union K
 */
export interface PickTemplatesOrServiceIdOrHasMultipleServicesOrServiceIdsOrImageDataOrTemplatesNameOrUrl { 
    templates_name?: string;
    url?: string;
    imageData?: string;
    service_id?: number;
    service_ids?: Array<number>;
    has_multiple_services?: boolean;
}

