/**
 * SAL Finance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdatePaymentDto { 
    id: number;
    txType?: UpdatePaymentDto.TxTypeEnum;
    paymentMethod?: UpdatePaymentDto.PaymentMethodEnum;
    online_reference?: string;
    invoiceNumber?: number;
    amount?: number;
    quantity?: number;
    itemId?: string;
    itemName?: string;
    itemCode?: string;
    payerId?: string;
    payerName?: string;
    payerUsername?: string;
    payerPhone?: string;
    payerSex?: string;
    departmentRoute?: string;
    payerIdNumber?: string;
    appointmentId?: string;
    originalAmount?: number;
    hmoApprovalCode?: string;
    hmoAmount?: number;
    hmoStatus?: UpdatePaymentDto.HmoStatusEnum;
    hmoCode?: string;
    payerHmoCode?: string;
    hmoVerifiedById?: string;
    hmoVerifiedByName?: string;
    payerPackage?: string;
    subaccount?: string;
    receiverId?: string;
    receiverName?: string;
    receiverUsername?: string;
    raisedById?: string;
    raisedByName?: string;
    requestId?: string;
    requestCategoryKey?: string;
    requestCategory?: string;
    organizationId?: string;
    slug?: string;
    receivedAt?: string;
}
export namespace UpdatePaymentDto {
    export type TxTypeEnum = 'income' | 'expense' | 'discount' | 'reversal';
    export const TxTypeEnum = {
        Income: 'income' as TxTypeEnum,
        Expense: 'expense' as TxTypeEnum,
        Discount: 'discount' as TxTypeEnum,
        Reversal: 'reversal' as TxTypeEnum
    };
    export type PaymentMethodEnum = 'cash' | 'pos' | 'cheque' | 'transfer' | 'online' | 'pay-later' | 'exempted' | 'hmo';
    export const PaymentMethodEnum = {
        Cash: 'cash' as PaymentMethodEnum,
        Pos: 'pos' as PaymentMethodEnum,
        Cheque: 'cheque' as PaymentMethodEnum,
        Transfer: 'transfer' as PaymentMethodEnum,
        Online: 'online' as PaymentMethodEnum,
        PayLater: 'pay-later' as PaymentMethodEnum,
        Exempted: 'exempted' as PaymentMethodEnum,
        Hmo: 'hmo' as PaymentMethodEnum
    };
    export type HmoStatusEnum = 'approved' | 'cancelled' | 'default' | 'in-progress';
    export const HmoStatusEnum = {
        Approved: 'approved' as HmoStatusEnum,
        Cancelled: 'cancelled' as HmoStatusEnum,
        Default: 'default' as HmoStatusEnum,
        InProgress: 'in-progress' as HmoStatusEnum
    };
}


