export * from './genericRequests.service';
import { GenericRequestsService } from './genericRequests.service';
export * from './labRequests.service';
import { LabRequestsService } from './labRequests.service';
export * from './labResults.service';
import { LabResultsService } from './labResults.service';
export * from './radiologyRequests.service';
import { RadiologyRequestsService } from './radiologyRequests.service';
export * from './radiologyResults.service';
import { RadiologyResultsService } from './radiologyResults.service';
export * from './statistics.service';
import { StatisticsService } from './statistics.service';
export const APIS = [GenericRequestsService, LabRequestsService, LabResultsService, RadiologyRequestsService, RadiologyResultsService, StatisticsService];
