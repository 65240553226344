/**
 * Admissions
 * Home Clinic - Admissions
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateRoomTransferRecordDto { 
    fromRoom?: number;
    toRoom?: number;
    fromBed?: number;
    toBed?: number;
    id: number;
    admissionRecord?: number;
    hospitalNumber?: string;
    transferedAt?: string;
    roomCost?: number;
    transferedById?: string;
    transferedByName?: string;
}

